.react-date-picker__wrapper {
  border: 1px solid #ffaa00;
  border-radius: 10px;
  width: 311px;
  background: #f2f2f2;
}

.picker {
  display: flex;
  width: 311px;
  flex-direction: column;
  margin: auto;
  justify-content: center;
}
