@tailwind base;
@tailwind components;
@tailwind utilities;
/* 100 */
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis'), url(./fonts/Metropolis-Thin.woff) format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis'),
    url(./fonts/Metropolis-ThinItalic.woff) format('woff');
  font-weight: 100;
  font-style: italic;
}

/* 200 */
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-ExtraLight'),
    url(./fonts/Metropolis-ExtraLight.woff) format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-ExtraLightItalic'),
    url(./fonts/Metropolis-ExtraLightItalic.woff) format('woff');
  font-weight: 200;
  font-style: italic;
}

/* 300 */
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-Light'),
    url(./fonts/Metropolis-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-LightItalic'),
    url(./fonts/Metropolis-LightItalic.woff) format('woff');
  font-weight: 300;
  font-style: italic;
}

/* 400 */
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-Regular'),
    url(./fonts/Metropolis-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-RegularItalic'),
    url(./fonts/Metropolis-RegularItalic.woff) format('woff');
  font-weight: 400;
  font-style: italic;
}

/* 500 */
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-Medium'),
    url(./fonts/Metropolis-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-MediumItalic'),
    url(./fonts/Metropolis-MediumItalic.woff) format('woff');
  font-weight: 500;
  font-style: italic;
}

/* 600 */
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-SemiBold'),
    url(./fonts/Metropolis-SemiBold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-SemiBoldItalic'),
    url(./fonts/Metropolis-SemiBoldItalic.woff) format('woff');
  font-weight: 600;
  font-style: italic;
}

/* 700 */
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-Bold'),
    url(./fonts/Metropolis-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-BoldItalic'),
    url(./fonts/Metropolis-BoldItalic.woff) format('woff');
  font-weight: 700;
  font-style: italic;
}

/* 800 */
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-ExtraBold'),
    url(./fonts/Metropolis-ExtraBold.woff) format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-ExtraBoldItalic'),
    url(./fonts/Metropolis-ExtraBoldItalic.woff) format('woff');
  font-weight: 800;
  font-style: italic;
}

/* 900 */
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-Black'),
    url(./fonts/Metropolis-Black.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-BlackItalic'),
    url(./fonts/Metropolis-BlackItalic.woff) format('woff');
  font-weight: 900;
  font-style: italic;
}
